import * as _masonryLayout2 from "masonry-layout";

var _masonryLayout = "default" in _masonryLayout2 ? _masonryLayout2.default : _masonryLayout2;

import * as _imagesloaded2 from "imagesloaded";

var _imagesloaded = "default" in _imagesloaded2 ? _imagesloaded2.default : _imagesloaded2;

import * as _assign2 from "lodash/assign";

var _assign = "default" in _assign2 ? _assign2.default : _assign2;

import * as _elementResizeDetector2 from "element-resize-detector";

var _elementResizeDetector = "default" in _elementResizeDetector2 ? _elementResizeDetector2.default : _elementResizeDetector2;

import * as _debounce2 from "lodash/debounce";

var _debounce = "default" in _debounce2 ? _debounce2.default : _debounce2;

import * as _omit2 from "lodash/omit";

var _omit = "default" in _omit2 ? _omit2.default : _omit2;

import * as _propTypes2 from "prop-types";

var _propTypes = "default" in _propTypes2 ? _propTypes2.default : _propTypes2;

import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

import * as _createReactClass2 from "create-react-class";

var _createReactClass = "default" in _createReactClass2 ? _createReactClass2.default : _createReactClass2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var isBrowser = typeof window !== "undefined";
var Masonry = isBrowser ? window.Masonry || _masonryLayout : null;
var imagesloaded = isBrowser ? _imagesloaded : null;
var assign = _assign;
var elementResizeDetectorMaker = _elementResizeDetector;
var debounce = _debounce;
var omit = _omit;
var PropTypes = _propTypes;
var React = _react;
var createReactClass = _createReactClass;
var propTypes = {
  enableResizableChildren: PropTypes.bool,
  disableImagesLoaded: PropTypes.bool,
  onImagesLoaded: PropTypes.func,
  updateOnEachImageLoad: PropTypes.bool,
  options: PropTypes.object,
  imagesLoadedOptions: PropTypes.object,
  elementType: PropTypes.string,
  onLayoutComplete: PropTypes.func,
  onRemoveComplete: PropTypes.func
};
var MasonryComponent = createReactClass({
  masonry: false,
  erd: undefined,
  latestKnownDomChildren: [],
  displayName: "MasonryComponent",
  imagesLoadedCancelRef: undefined,
  propTypes: propTypes,
  getDefaultProps: function () {
    return {
      enableResizableChildren: false,
      disableImagesLoaded: false,
      updateOnEachImageLoad: false,
      options: {},
      imagesLoadedOptions: {},
      className: "",
      elementType: "div",
      onLayoutComplete: function () {},
      onRemoveComplete: function () {}
    };
  },
  initializeMasonry: function (force) {
    if (!(this || _global).masonry || force) {
      (this || _global).masonry = new Masonry((this || _global).masonryContainer, (this || _global).props.options);

      if ((this || _global).props.onLayoutComplete) {
        (this || _global).masonry.on("layoutComplete", (this || _global).props.onLayoutComplete);
      }

      if ((this || _global).props.onRemoveComplete) {
        (this || _global).masonry.on("removeComplete", (this || _global).props.onRemoveComplete);
      }

      (this || _global).latestKnownDomChildren = this.getCurrentDomChildren();
    }
  },
  getCurrentDomChildren: function () {
    var node = (this || _global).masonryContainer;
    var children = (this || _global).props.options.itemSelector ? node.querySelectorAll((this || _global).props.options.itemSelector) : node.children;
    return Array.prototype.slice.call(children);
  },
  diffDomChildren: function () {
    var forceItemReload = false;

    var knownChildrenStillAttached = (this || _global).latestKnownDomChildren.filter(function (element) {
      /*
       * take only elements attached to DOM
       * (aka the parent is the masonry container, not null)
       * otherwise masonry would try to "remove it" again from the DOM
       */
      return !!element.parentNode;
    });
    /*
     * If not all known children are attached to the dom - we have no other way of notifying
     * masonry to remove the ones not still attached besides invoking a complete item reload.
     * basically all the rest of the code below does not matter in that case.
     */


    if (knownChildrenStillAttached.length !== (this || _global).latestKnownDomChildren.length) {
      forceItemReload = true;
    }

    var currentDomChildren = this.getCurrentDomChildren();
    /*
     * Since we are looking for a known child which is also attached to the dom AND
     * not attached to the dom at the same time - this would *always* produce an empty array.
     */

    var removed = knownChildrenStillAttached.filter(function (attachedKnownChild) {
      return !~currentDomChildren.indexOf(attachedKnownChild);
    });
    /*
     * This would get any children which are attached to the dom but are *unkown* to us
     * from previous renders
     */

    var newDomChildren = currentDomChildren.filter(function (currentChild) {
      return !~knownChildrenStillAttached.indexOf(currentChild);
    });
    var beginningIndex = 0; // get everything added to the beginning of the DOMNode list

    var prepended = newDomChildren.filter(function (newChild) {
      var prepend = beginningIndex === currentDomChildren.indexOf(newChild);

      if (prepend) {
        // increase the index
        beginningIndex++;
      }

      return prepend;
    }); // we assume that everything else is appended

    var appended = newDomChildren.filter(function (el) {
      return prepended.indexOf(el) === -1;
    });
    /*
     * otherwise we reverse it because so we're going through the list picking off the items that
     * have been added at the end of the list. this complex logic is preserved in case it needs to be
     * invoked
     *
     * var endingIndex = currentDomChildren.length - 1;
     *
     * newDomChildren.reverse().filter(function(newChild, i){
     *     var append = endingIndex == currentDomChildren.indexOf(newChild);
     *
     *     if (append) {
     *         endingIndex--;
     *     }
     *
     *     return append;
     * });
     */
    // get everything added to the end of the DOMNode list

    var moved = [];
    /*
     * This would always be true (see above about the lofic for "removed")
     */

    if (removed.length === 0) {
      /*
       * 'moved' will contain some random elements (if any) since the "knownChildrenStillAttached" is a filter
       * of the "known" children which are still attached - All indexes could basically change. (for example
       * if the first element is not attached)
       * Don't trust this array.
       */
      moved = knownChildrenStillAttached.filter(function (child, index) {
        return index !== currentDomChildren.indexOf(child);
      });
    }

    (this || _global).latestKnownDomChildren = currentDomChildren;
    return {
      old: knownChildrenStillAttached,
      // Not used
      new: currentDomChildren,
      // Not used
      removed: removed,
      appended: appended,
      prepended: prepended,
      moved: moved,
      forceItemReload: forceItemReload
    };
  },
  performLayout: function () {
    var diff = this.diffDomChildren();
    var reloadItems = diff.forceItemReload || diff.moved.length > 0; // Would never be true. (see comments of 'diffDomChildren' about 'removed')

    if (diff.removed.length > 0) {
      if ((this || _global).props.enableResizableChildren) {
        diff.removed.forEach((this || _global).erd.removeAllListeners, (this || _global).erd);
      }

      (this || _global).masonry.remove(diff.removed);

      reloadItems = true;
    }

    if (diff.appended.length > 0) {
      (this || _global).masonry.appended(diff.appended);

      if (diff.prepended.length === 0) {
        reloadItems = true;
      }

      if ((this || _global).props.enableResizableChildren) {
        diff.appended.forEach((this || _global).listenToElementResize, this || _global);
      }
    }

    if (diff.prepended.length > 0) {
      (this || _global).masonry.prepended(diff.prepended);

      if ((this || _global).props.enableResizableChildren) {
        diff.prepended.forEach((this || _global).listenToElementResize, this || _global);
      }
    }

    if (reloadItems) {
      (this || _global).masonry.reloadItems();
    }

    (this || _global).masonry.layout();
  },
  derefImagesLoaded: function () {
    this.imagesLoadedCancelRef();
    (this || _global).imagesLoadedCancelRef = undefined;
  },
  imagesLoaded: function () {
    if ((this || _global).props.disableImagesLoaded) {
      return;
    }

    if ((this || _global).imagesLoadedCancelRef) {
      this.derefImagesLoaded();
    }

    var event = (this || _global).props.updateOnEachImageLoad ? "progress" : "always";
    var handler = debounce(function (instance) {
      if ((this || _global).props.onImagesLoaded) {
        (this || _global).props.onImagesLoaded(instance);
      }

      (this || _global).masonry.layout();
    }.bind(this || _global), 100);
    var imgLoad = imagesloaded((this || _global).masonryContainer, (this || _global).props.imagesLoadedOptions).on(event, handler);

    (this || _global).imagesLoadedCancelRef = function () {
      imgLoad.off(event, handler);
      handler.cancel();
    };
  },
  initializeResizableChildren: function () {
    if (!(this || _global).props.enableResizableChildren) {
      return;
    }

    (this || _global).erd = elementResizeDetectorMaker({
      strategy: "scroll"
    });

    (this || _global).latestKnownDomChildren.forEach((this || _global).listenToElementResize, this || _global);
  },
  listenToElementResize: function (el) {
    (this || _global).erd.listenTo(el, function () {
      (this || _global).masonry.layout();
    }.bind(this || _global));
  },
  destroyErd: function () {
    if ((this || _global).erd) {
      (this || _global).latestKnownDomChildren.forEach((this || _global).erd.uninstall, (this || _global).erd);
    }
  },
  componentDidMount: function () {
    this.initializeMasonry();
    this.initializeResizableChildren();
    this.imagesLoaded();
  },
  componentDidUpdate: function () {
    this.performLayout();
    this.imagesLoaded();
  },
  componentWillUnmount: function () {
    this.destroyErd(); // unregister events

    if ((this || _global).props.onLayoutComplete) {
      (this || _global).masonry.off("layoutComplete", (this || _global).props.onLayoutComplete);
    }

    if ((this || _global).props.onRemoveComplete) {
      (this || _global).masonry.off("removeComplete", (this || _global).props.onRemoveComplete);
    }

    if ((this || _global).imagesLoadedCancelRef) {
      this.derefImagesLoaded();
    }

    (this || _global).masonry.destroy();
  },
  setRef: function (n) {
    (this || _global).masonryContainer = n;
  },
  render: function () {
    var props = omit((this || _global).props, Object.keys(propTypes));
    return React.createElement((this || _global).props.elementType, assign({}, props, {
      ref: (this || _global).setRef
    }), (this || _global).props.children);
  }
});
exports = MasonryComponent;
exports.default = MasonryComponent;
export default exports;